import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import { useIncomingGoodDetailContext } from "./providers/CreateEdit";
import { Card, CardBody } from "reactstrap";
import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/material_blue.css";
import "flatpickr/dist/l10n/it";
import { Loader } from "../../Components/common/Loader";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import {
  getAllArticleVariants,
  getAllArticles,
  getAllWarehouseArticles,
} from "../../utilities/asyncSelectCallAPI";

import InputBox from "../../Components/common/InputBox";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIncomingGoodContext } from "../IncomingGoods/providers/CreateEdit";
import {
  getArticleOptionStyle,
  getWarehouseArticleOptionStyle,
} from "../../Components/common/AsyncSelect/utilities";

export const CreateEdit = () => {
  const context = useIncomingGoodDetailContext();
  const createEditIncomingGoodContext = useIncomingGoodContext();

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const incomingGoodDetail = {
      ...context.incomingGoodDetail,
      [name]: value,
    };

    context.editIncomingGoodDetail(incomingGoodDetail);
    context.removeError(name);
  };

  const handleAsyncSelectChange = (selector, value) => {
    const incomingGoodDetail = {
      ...context.incomingGoodDetail,
      [selector]: value.article,
    };

    context.editIncomingGoodDetail(incomingGoodDetail);
    context.removeError(selector + "Id");
  };

  useEffect(() => {
    if (!context.id) {
      if (context.autosave) {
        context.createIncomingGoodDetail();
      }
    } else {
      context.getIncomingGoodDetail();
    }
  }, []);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <label className="form-label">Prodotto:</label>
          <AsyncSelect
            name="article"
            optionValue="id"
            optionLabel="name"
            placeholder="Seleziona prodotto"
            isClearable={false}
            value={context?.incomingGoodDetail?.article}
            onSearch={handleAsyncSelectChange}
            loadOptions={(inputValue, pagination) => {
              return getAllWarehouseArticles(
                inputValue,
                pagination,
                {
                  orderBy: "asc",
                  selector: "article.name",
                },
                [
                  {
                    selector: "article.shippingRequired",
                    value: [{ value: "true", isBool: true }],
                  },
                ],
                createEditIncomingGoodContext?.incomingGood?.deliveryWarehouse
                  ?.id
              );
            }}
            optionStyle={getWarehouseArticleOptionStyle}
            error={context.incomingGoodDetailError?.response?.data?.articleId}
          />
        </div>
        <div className="col-3">
          <label className="form-label">Quantità attesa:</label>
          <InputBox
            name="expectedQuantity"
            value={context?.incomingGoodDetail?.expectedQuantity || ""}
            placeholder={"1"}
            type="positive-integer-number"
            onChange={handleInputChange}
            error={
              context.incomingGoodDetailError?.response?.data?.expectedQuantity
            }
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col">
          <label className="form-label">Note mittente:</label>
          <InputBox
            name="senderNote"
            value={context?.incomingGoodDetail?.senderNote || ""}
            placeholder={"Note"}
            type="text"
            onChange={handleInputChange}
            error={context.incomingGoodDetailError?.response?.data?.senderNote}
          />
        </div>
      </div>
      {!context.autosave && (
        <div className="row mt-3">
          <div className="col">
            <button
              className="btn btn-link p-0"
              onClick={() => context.callback()}
            >
              Annulla
            </button>
          </div>
          <div className="col text-end">
            <button
              className="btn btn-outline-success btn-sm btn btn-light"
              onClick={() => context.createEditIncomingGoodDetail(context.id)}
              disabled={context.incomingGoodDetailLoader}
            >
              {context.incomingGoodDetailLoader ? (
                <FontAwesomeIcon icon={faLoader} spin />
              ) : (
                "Salva"
              )}
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
