import React, { createContext, useState, useContext, useEffect } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { BrandSettingsService } from "./services/registry/brandSettings";
import { CompaniesService } from "./services/registry/companies";
import API from "./helpers/API";

const brandSettingsService = new BrandSettingsService();
const companiesService = new CompaniesService();

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const { accessTokenPayload } = useOidcAccessToken();
  const api = new API();

  const [brandSettings, setBrandSettings] = useState();
  const [owner, setOwner] = useState();
  const [domain, setDomain] = useState(api.getURL());
  const [scrollTop, setScrollTop] = useState(0);
  const [contentRef, setContentRef] = useState(null);

  const getBrandSettings = () => {
    brandSettingsService.getBrandSettingPublic().then((res) => {
      if (res.data) {
        setBrandSettings(res.data);
      }
    });
  };

  const getOwner = () => {
    companiesService.getCompany(accessTokenPayload?.owner).then((res) => {
      if (res.data) {
        setOwner(res.data);
      }
    });
  };

  useEffect(() => {
    if (brandSettings?.slug) {
      setDomain(`${brandSettings?.slug}.${api.getURLShort()}`);
    }
  }, [brandSettings]);

  useEffect(() => {
    getBrandSettings();
    if (accessTokenPayload) {
      getOwner();
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        brandSettings,
        owner,
        // language: navigator.language === "it" ? "it" : "en",
        language: "it",
        domain,
        scrollTop,
        setScrollTop,
        contentRef,
        setContentRef,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, useAppContext };
