import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppContext } from "../../AppProvider";
import {
  faAddressBook,
  faAt,
  faCity,
  faEdit,
  faICursor,
  faLoader,
  faPhoneAlt,
  faUser,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { dictionary } from "../../utilities/dictionary";
import InputBox from "../../Components/common/InputBox";
import {
  ContactHandlerProvider,
  useContactHandlerContext,
} from "./providers/Handler";
import { LocationHandlerProvider } from "../Locations/providers/Handler";
import { LocationHandler } from "../Locations/LocationHandler";
import { Modal, ModalBody } from "reactstrap";
import { Checkbox } from "../../Components/common/Checkbox";
import SearchLocality from "../../Components/common/SearchLocality/SearchLocality";
import { getAllRegistryContacts } from "../../utilities/asyncSelectCallAPI";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import InputBoxIcon from "../../Components/common/InputBoxIcon";
import { ButtonConfirm } from "../../Components/common/Buttons/ButtonConfirm";

const ContactHandler = ({
  action,
  myAddress = false,
  billingSettings,
  searchInAddressBook = true,
  title,
}) => {
  const { accessTokenPayload } = useOidcAccessToken();

  const [billingIsDifferentFromContact, setBillingIsDifferentFromContact] =
    useState(false);

  const appContext = useAppContext();
  const language = appContext.language;

  let context = useContactHandlerContext();

  const handleChange = (name, value) => {
    if (action !== "details") {
      const newContact = {
        ...context.contact,
        [name]: value,
      };

      context.createEditContact(newContact);
      context.removeError(name);
    }
  };

  const handleSearchLocalityOnChange = (selector, option) => {
    if (action !== "details") {
      context.createEditContact({
        ...context.contact,
        location: { ...context.contact.location, ...option.place },
      });
    }
  };

  useEffect(() => {
    if (context?.contact.id && billingSettings?.id) {
      setBillingIsDifferentFromContact(
        context?.contact.id !== billingSettings?.id
      );
    }
  }, [context?.contact.id]);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          {context?.contactLoader ? (
            <div className="row">
              <div className="col text-center">
                <FontAwesomeIcon icon={faLoader} spin size="2x" />
              </div>
            </div>
          ) : (
            <React.Fragment>
              <div className="row align-items-center">
                <div className="col">{title}</div>
                {action !== "details" &&
                  billingSettings &&
                  searchInAddressBook && (
                    <div className="col-6 text-end">
                      <ButtonConfirm
                        icon={false}
                        onClick={() =>
                          context?.setModal({
                            open: true,
                            action: "searchInAddressBook",
                            title:
                              dictionary["destination_address_book"][language],
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faAddressBook} />{" "}
                        {dictionary["search_address_book"][language]}
                      </ButtonConfirm>
                    </div>
                  )}
              </div>

              <div
                className="d-grid grid-column mt-2"
                style={{
                  gridTemplateColumns: `1fr`,
                }}
              >
                <div>
                  <label className="label-2">
                    {dictionary["company_name"][language]}
                  </label>
                  <InputBoxIcon
                    name="name"
                    value={context.contact?.name || ""}
                    placeholder={action !== "details" ? "Sede azienda" : ""}
                    type="text"
                    onChange={(e) => {
                      handleChange(e.target.name, e.target.value);
                    }}
                    disabled={action === "details"}
                    icon={faCity}
                    error={context.contactError?.response?.data?.name}
                  />
                </div>
                {billingSettings && (
                  <div>
                    <label className="label-2">
                      {dictionary["customer_reference"][language]}
                    </label>
                    <InputBoxIcon
                      name="externalReference"
                      value={context.contact?.externalReference || ""}
                      placeholder={action !== "details" ? "EXTREF" : ""}
                      type="text"
                      onChange={(e) => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      disabled={action === "details"}
                      icon={faICursor}
                      error={
                        context.contactError?.response?.data?.externalReference
                      }
                    />
                  </div>
                )}
              </div>
              <div
                className="d-grid grid-column align-items-end mt-1"
                style={{
                  gridTemplateColumns: `1fr`,
                }}
              >
                <div>
                  {action !== "details" ? (
                    <>
                      <label className="label-2">
                        {dictionary["address"][language]}
                      </label>
                      <SearchLocality
                        onChange={handleSearchLocalityOnChange}
                        value={context?.contact?.location}
                        placeholder="Via Roma, 10, 10121 Torino TO, Italia"
                      />
                    </>
                  ) : (
                    <>
                      <label className="label-2">
                        {dictionary["address"][language]}
                      </label>
                      <InputBox
                        disabled
                        value={context.contact?.location?.label}
                      />
                    </>
                  )}
                </div>
                {action !== "details" && (
                  <div>
                    <button
                      className="button-1 button-1-dark button-1-icon"
                      disabled={!context?.contact?.location?.id}
                      title={`${dictionary["edit"][language]} ${dictionary[
                        "address"
                      ][language].toLowerCase()}`}
                      onClick={() => {
                        if (context?.contact?.location?.id) {
                          context?.setModal({
                            open: true,
                            action: "editLocation",
                            title: `${
                              dictionary["edit"][language]
                            } ${dictionary["address"][language].toLowerCase()}`,
                          });
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </div>
                )}
              </div>
              {billingSettings && (
                <>
                  <div
                    className="d-grid grid-column mt-1"
                    style={{
                      gridTemplateColumns: `1fr`,
                    }}
                  >
                    <div>
                      <label className="label-2">
                        {dictionary["name"][language]}{" "}
                        {dictionary["and"][language]}{" "}
                        {dictionary["surname"][language]}{" "}
                      </label>
                      <InputBoxIcon
                        name="contactName"
                        value={context.contact?.contactName || ""}
                        placeholder={action !== "details" ? "Mario Rossi" : ""}
                        type="text"
                        onChange={(e) => {
                          handleChange(e.target.name, e.target.value);
                        }}
                        disabled={action === "details"}
                        icon={faUser}
                        error={
                          context.contactError?.response?.data?.contactName
                        }
                      />
                    </div>
                  </div>

                  <div
                    className="d-grid grid-column mt-1"
                    style={{
                      gridTemplateColumns: `1fr 1fr`,
                    }}
                  >
                    <div>
                      <label className="label-2">
                        {dictionary["email"][language]}
                      </label>
                      <InputBoxIcon
                        name="email"
                        value={context.contact?.email || ""}
                        placeholder={
                          action !== "details" ? "email@allabox.it" : ""
                        }
                        type="text"
                        onChange={(e) => {
                          handleChange(e.target.name, e.target.value);
                        }}
                        disabled={action === "details"}
                        icon={faAt}
                        error={context.contactError?.response?.data?.email}
                      />
                    </div>
                    <div>
                      <label className="label-2">
                        {dictionary["phone"][language]}
                      </label>
                      <InputBoxIcon
                        name="phone"
                        value={context.contact?.phone || ""}
                        placeholder={action !== "details" ? "3999999999" : ""}
                        type="text"
                        onChange={(e) => {
                          handleChange(e.target.name, e.target.value);
                        }}
                        disabled={action === "details"}
                        icon={faPhoneAlt}
                        error={context.contactError?.response?.data?.phone}
                      />
                    </div>
                  </div>

                  <div
                    className="d-grid grid-column mt-1"
                    style={{
                      gridTemplateColumns: `1fr 1fr 1fr`,
                    }}
                  >
                    <div>
                      <Checkbox
                        name="priv"
                        checked={context.contact?.priv || false}
                        onChange={(e) =>
                          handleChange(e.target.name, !context.contact?.priv)
                        }
                        label={dictionary["private"][language]}
                        disabled={action === "details"}
                      />
                    </div>
                    <div>
                      <Checkbox
                        name="gdo"
                        checked={context.contact?.gdo || false}
                        onChange={(e) =>
                          handleChange(e.target.name, !context.contact?.gdo)
                        }
                        label={dictionary["gdo"][language]}
                        disabled={action === "details"}
                      />
                    </div>
                    <div>
                      <Checkbox
                        name="ztl"
                        checked={context.contact?.ztl || false}
                        onChange={(e) =>
                          handleChange(e.target.name, !context.contact?.ztl)
                        }
                        label={dictionary["ztl"][language]}
                        disabled={action === "details"}
                      />
                    </div>
                  </div>
                </>
              )}

              <>
                <h3 className="m-0 mt-2">{dictionary["billing"][language]}</h3>
                <div
                  className="d-grid grid-column mt-1"
                  style={{
                    gridTemplateColumns: `1fr 1fr 1fr 1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary["vat_number"][language]}
                    </label>
                    <InputBox
                      name="vatNumber"
                      value={context.contact?.vatNumber || ""}
                      placeholder={action !== "details" ? "86334519757" : ""}
                      type="text"
                      onChange={(e) => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      disabled={action === "details"}
                      error={context.contactError?.response?.data?.vatNumber}
                    />
                  </div>
                  <div>
                    <label className="label-2">SDI</label>
                    <InputBox
                      name="sdi"
                      value={context.contact?.sdi || ""}
                      placeholder={action !== "details" ? "SDI" : ""}
                      type="text"
                      onChange={(e) => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      disabled={action === "details"}
                      error={context.contactError?.response?.data?.sdi}
                    />
                  </div>
                  <div>
                    <label className="label-2">PEC</label>
                    <InputBox
                      name="pec"
                      value={context.contact?.pec || ""}
                      placeholder={
                        action !== "details" ? "email@allabox.it" : ""
                      }
                      type="text"
                      onChange={(e) => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      disabled={action === "details"}
                      error={context.contactError?.response?.data?.pec}
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["fiscal_code"][language]}
                    </label>
                    <InputBox
                      name="fiscalCode"
                      value={context.contact?.fiscalCode || ""}
                      type="text"
                      onChange={(e) => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      disabled={action === "details"}
                      error={context.contactError?.response?.data?.fiscalCode}
                    />
                  </div>
                </div>
                {action !== "details" &&
                  billingSettings &&
                  context?.contact?.location && (
                    <div
                      className="d-grid grid-column mt-2"
                      style={{
                        gridTemplateColumns: `1fr`,
                      }}
                    >
                      <div>
                        <ButtonConfirm
                          icon={false}
                          className="w-100"
                          onClick={() => {
                            context?.createEditInRegistryAddressbook();
                          }}
                        >
                          {context?.contact?.registryId
                            ? `${dictionary["edit"][language]} ${
                                dictionary["in"][language]
                              }  ${dictionary["address_book"][
                                language
                              ].toLowerCase()}`
                            : `${dictionary["add"][language]} ${
                                dictionary["in"][language]
                              }  ${dictionary["address_book"][
                                language
                              ].toLowerCase()}`}
                        </ButtonConfirm>
                      </div>
                    </div>
                  )}
                {context?.contact?.id && billingSettings?.enabled && (
                  <div className="mt-1">
                    <Checkbox
                      name="billing"
                      value={billingIsDifferentFromContact}
                      checked={billingIsDifferentFromContact}
                      onChange={() => {
                        if (
                          billingIsDifferentFromContact &&
                          billingSettings?.onChange
                        ) {
                          billingSettings?.onChange();
                        }
                        setBillingIsDifferentFromContact(
                          !billingIsDifferentFromContact
                        );
                      }}
                      label={
                        dictionary["billing_different_from_recipient"][language]
                      }
                      disabled={action === "details"}
                      className={`${action === "details" && "cursor-default"}`}
                    />

                    {billingIsDifferentFromContact && (
                      <ContactHandlerProvider
                        service="logistic"
                        id={
                          context?.contact.id !== billingSettings?.id
                            ? billingSettings?.id
                            : null
                        }
                        callback={(contact) => {
                          if (billingSettings.onChange) {
                            billingSettings?.onChange(contact);
                          }
                        }}
                        defaultContact={{ name: context?.contact?.name }}
                      >
                        <ContactHandler
                          action={action}
                          searchInAddressBook={false}
                        />
                      </ContactHandlerProvider>
                    )}
                  </div>
                )}
              </>
            </React.Fragment>
          )}
        </div>
      </div>

      <Modal isOpen={context?.modal.open} size={context?.modal.size}>
        <ModalBody>
          <div className="row mb-3">
            <div className="col">
              <label className="label-4 text-center">
                {context?.modal.title}{" "}
              </label>
            </div>
          </div>
          {searchInAddressBook &&
            context?.modal.action === "searchInAddressBook" && (
              <React.Fragment>
                <div>
                  <label className="label-2">
                    {dictionary["search_address_book"][language]}
                  </label>
                  <AsyncSelect
                    name="contact"
                    optionValue="id"
                    optionLabel="name"
                    placeholder={dictionary["search"][language]}
                    isClearable={false}
                    onSearch={(selector, value) => {
                      value.registryId = value.id;
                      value.location.registryId = value.location.id;
                      delete value.id;
                      delete value.location.id;
                      const newContact = {
                        ...context.contact,
                        ...value,
                        location: {
                          ...context.contact.location,
                          ...value.location,
                        },
                      };
                      context?.createEditContact(newContact);
                      context?.setModal({ open: false });
                    }}
                    loadOptions={(inputValue, pagination) => {
                      return getAllRegistryContacts(
                        inputValue,
                        pagination,
                        {
                          orderBy: "asc",
                          selector: "name",
                        },
                        [
                          {
                            selector: "myAddress",
                            value: myAddress,
                            preciseSearch: true,
                          },
                          {
                            selector: "ownerId",
                            value: accessTokenPayload.owner,
                            preciseSearch: true,
                          },
                        ]
                      );
                    }}
                    optionStyle={(option) => {
                      return option.name + " - " + option.location.label;
                    }}
                  />
                </div>
                <div className="d-flex mt-3">
                  <ButtonUtility
                    className="w-25"
                    onClick={() => {
                      context?.setModal({ open: false });
                    }}
                  >
                    {dictionary["cancel"][language]}
                  </ButtonUtility>
                </div>
              </React.Fragment>
            )}
          {context?.modal.action === "editLocation" && (
            <React.Fragment>
              <LocationHandlerProvider
                service={context?.service}
                id={context?.contact?.location?.id}
                callback={(location) => {
                  context?.setModal({ open: false });

                  context.removeAllErrors();
                  if (context?.contact?.id) {
                    context?.getContact(context?.contact?.id);
                    return;
                  }
                  context.createEditContact({ ...context.contact, location });
                }}
              >
                <LocationHandler />
              </LocationHandlerProvider>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ContactHandler;
