import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  callErrorToast,
  callSuccessToast,
  modalDefault,
  valueIsEmpty,
} from "../../../utilities";
import { AddressBookService as RegistryAddressBookService } from "../../../services/registry/addressBook";
import { LocationsService as RegistryLocationsService } from "../../../services/registry/locations";
import { AddressBookService as LogisticAddressBookService } from "../../../services/logistic/addressBook";
import { LocationsService as LogisticLocationsService } from "../../../services/logistic/locations";
import { buildLocationLabel } from "../../Locations/utilities/index";
import context from "react-bootstrap/esm/AccordionContext";

const ContactHandlerContext = createContext();

const ContactHandlerProvider = ({
  children,
  id,
  autosave = false,
  callback,
  service = "registry",
  defaultContact = {},
}) => {
  let addressBookService = new RegistryAddressBookService();
  let locationsService = new RegistryLocationsService();
  switch (service) {
    case "logistic":
      addressBookService = new LogisticAddressBookService();
      locationsService = new LogisticLocationsService();
  }

  const [modal, setModal] = useState({
    ...modalDefault,
  });

  const [contact, setContact] = useState({
    gdo: false,
    priv: false,
    ztl: false,
    myAddress: false,
    ...defaultContact,
  });
  const [contactError, setContactError] = useState(null);
  const [contactLoader, setContactLoader] = useState(id ? true : false);

  const prevContactError = useRef();

  const getContact = (id) => {
    addressBookService
      .get(id)
      .then((res) => {
        res.data.locationId = res.data.location.id;
        res.data.registryId = contact.registryId;
        res.data.location.registryId = contact.location?.registryId;
        setContact(res.data);
        setContactLoader(false);
      })
      .catch((err) => {
        setContactError(err);
        setContactLoader(false);
      });
  };

  const createEditInRegistryAddressbook = () => {
    addressBookService = new RegistryAddressBookService();
    locationsService = new RegistryLocationsService();
    const newContact = {
      ...contact,
      location: { ...contact.location },
      myAddress: false,
    };
    if (newContact.registryId) {
      newContact.id = newContact.registryId;
      newContact.location.id = newContact.location.registryId;
      newContact.locationId = newContact.location.registryId;
      editRegistryContact(newContact);
      return;
    }
    createRegistryContact(newContact);
  };

  const createRegistryContact = (newContact) => {
    locationsService
      .create(newContact.location)
      .then((locationRes) => {
        addressBookService
          .create({ ...newContact, locationId: locationRes.data.id })
          .then((contactRes) => {
            newContact.registryId = contactRes.data.id;
            newContact.location.registryId = locationRes.data.id;
            newContact.id = contact.id;
            newContact.location.id = contact.location.id;
            setContact(newContact);
            callSuccessToast(contactRes);
          })
          .catch((err) => {
            setContactError(err);
          });
      })
      .catch((err) => {
        setContactError(err);
      });
  };

  const editRegistryContact = (newContact) => {
    locationsService
      .edit(newContact.location)
      .then(() => {
        addressBookService
          .edit({ ...newContact, locationId: newContact.location.id })
          .then((contactRes) => {
            callSuccessToast(contactRes);
          })
          .catch((err) => {
            setContactError(err);
          });
      })
      .catch((err) => {
        setContactError(err);
      });
  };

  const createEditContact = (newContact) => {
    if (!newContact.id && newContact.location && !newContact.location?.id) {
      createLocation(newContact);
    } else if (newContact.id && newContact.location?.id) {
      editLocation(newContact);
    } else if (!newContact.id && newContact.location?.id) {
      createContact(newContact);
    } else {
      setContact(newContact);
    }
  };

  const createLocation = (contact) => {
    contact.location.label = buildLocationLabel(contact.location);
    locationsService
      .create(contact.location)
      .then((locationRes) => {
        contact = {
          ...contact,
          location: locationRes.data,
          locationId: locationRes.data.id,
        };
        createContact(contact);
      })
      .catch((err) => {
        setContactError(err);
      });
  };

  const createContact = (contact) => {
    addressBookService
      .create(contact)
      .then((contactRes) => {
        setContact(contactRes.data);
        if (callback) {
          callback(contactRes.data);
        }
      })
      .catch((err) => {
        setContact(contact);
        setContactError(err);
      });
  };

  const editLocation = (contact) => {
    contact.location.label = buildLocationLabel(contact.location);
    locationsService
      .edit(contact.location)
      .then(() => {
        editContact(contact);
      })
      .catch((err) => {
        setContactError(err);
      });
  };

  const editContact = (contact) => {
    addressBookService
      .edit({ ...contact, locationId: contact.location.id })
      .then(() => {
        setContact(contact);
        if (callback) {
          callback(contact);
        }
      })
      .catch((err) => {
        setContact(contact);
        setContactError(err);
      });
  };

  const removeError = (property) => {
    const newContactError = { ...contactError };
    delete newContactError.response?.data[property];
    if (!valueIsEmpty(newContactError)) {
      prevContactError.current = newContactError;
      setContactError(newContactError);
    }
  };

  const removeAllErrors = () => {
    prevContactError.current = null;
    setContactError(null);
  };

  useEffect(() => {
    if (
      contactError &&
      JSON.stringify(prevContactError.current) !== JSON.stringify(contactError)
    ) {
      callErrorToast(contactError);
    }
  }, [contactError]);

  useEffect(() => {
    if (id) {
      getContact(id);
      return;
    }
  }, [id]);

  return (
    <ContactHandlerContext.Provider
      value={{
        contact,
        contactError,
        contactLoader,

        getContact,
        createEditContact,

        createEditInRegistryAddressbook,
        modal,
        setModal,
        removeError,
        removeAllErrors,
        callback,
        autosave,
        service,
      }}
    >
      {children}
    </ContactHandlerContext.Provider>
  );
};

const useContactHandlerContext = () => {
  return useContext(ContactHandlerContext);
};

export { ContactHandlerProvider, useContactHandlerContext };
