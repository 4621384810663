import React, { createContext, useContext, useEffect, useState } from "react";
import { OrdersService } from "../../../services/logistic/orders";
import { callErrorToast } from "../../../utilities";

const DeleteShipmentsInOrdersContext = createContext();

const ordersService = new OrdersService();

const DeleteShipmentsInOrdersProvider = ({ children, data, callback }) => {
  const [orders, setOrders] = useState(data);
  const [ordersError, setOrdersError] = useState(null);
  const [ordersLoader, setOrdersLoader] = useState(false);

  const deleteShipments = () => {
    setOrdersLoader(true);
    const promises = orders.map((elem) => {
      return elem.shipments.map((s) => {
        return ordersService.deleteShipment(elem.id, s.internalReference);
      });
    });
    Promise.all(promises)
      .then((response) => {
        setOrdersLoader(false);
        if (callback) {
          callback(true);
        }
      })
      .catch((err) => {
        setOrdersError(err);
      });
  };

  useEffect(() => {
    if (ordersError) {
      callErrorToast(ordersError);
    }
  }, [ordersError]);

  return (
    <DeleteShipmentsInOrdersContext.Provider
      value={{
        orders,
        ordersError,
        ordersLoader,
        deleteShipments,

        callback,
      }}
    >
      {children}
    </DeleteShipmentsInOrdersContext.Provider>
  );
};

const useDeleteShipmentsInOrdersContext = () => {
  return useContext(DeleteShipmentsInOrdersContext);
};

export { DeleteShipmentsInOrdersProvider, useDeleteShipmentsInOrdersContext };
