import React, { useRef, useState } from "react";
import "../../assets/scss/pages/manifest.scss";
import moment from "moment";
import Barcode from "react-barcode";
import { List } from "../../Components/common/List/List";
import { ListProvider } from "../../Components/common/List/Providers/List";
import { dictionary } from "../../utilities/dictionary";
import { useAppContext } from "../../AppProvider";
import { useListProps } from "./utilities/list";
import { ButtonConfirm } from "../../Components/common/Buttons/ButtonConfirm";
import LogoDark from "../../assets/images/logo-black.svg";
import { useReactToPrint } from "react-to-print";

const Manifest = () => {
  const [carrierName, setCarrierName] = useState();

  const appContext = useAppContext();
  const listProps = useListProps();

  const listRef = useRef(null);
  const contentRef = useRef(null);

  const language = appContext?.language;

  const renderHeader = (data) => {
    const shipment = data?.content?.[0];
    setCarrierName(shipment?.carrierName);
    return (
      <div className="row m-0 mb-3 align-items-center">
        <div className="col text-center">
          <img src={appContext?.owner?.logoDarkUrl}></img>
        </div>
        <div className="col-6 text-center">
          <label className="label-2">
            {moment().format("DD/MM/YYYY HH:mm")}
          </label>
          <label className="label-2">{shipment?.senderName}</label>
          <label className="label-2">{shipment?.senderAddress}</label>
        </div>
        <div className="col text-center">
          {shipment?.carrierLogo && <img src={shipment?.carrierLogo}></img>}
        </div>
      </div>
    );
  };

  const renderItem = (item, key) => {
    return <ListItem item={item} index={key} />;
  };

  const renderFooter = (data) => {
    return (
      <div className="row m-0 my-5 align-items-center justify-content-end">
        <div className="col-4 text-center">
          <label className="label-2 pb-5 w-75 border-bottom border-dark">
            {dictionary["signature_for_acceptance"][language]}
          </label>
        </div>
      </div>
    );
  };

  const printFn = useReactToPrint({
    contentRef: contentRef,
    documentTitle: `${moment().format("YYYY-MM-DD")}_${carrierName?.replace(
      / /g,
      "_"
    )}_manifest`,
  });

  const handleOnClick = React.useCallback(() => {
    printFn();
  }, [printFn]);

  document.body.classList.add("overflow-auto");

  const internalReferences = JSON.parse(
    localStorage.getItem("manifest_filters")
  )?.internalReference;

  return (
    <div ref={contentRef}>
      <div className="manifest-header">
        <div className="polygon"></div>
        <img src={LogoDark} />
        <ButtonConfirm onClick={handleOnClick}>
          {dictionary["print"][language]}
        </ButtonConfirm>
      </div>

      <div className="manifest-content">
        {internalReferences?.length > 0 ? (
          <ListProvider
            path={listProps.path}
            defaultPagination={{
              page: 0,
              size: internalReferences.length,
            }}
            filters={listProps.filters}
          >
            <List
              ref={listRef}
              title={listProps.title}
              create={listProps.create}
              banner={listProps.banner}
              pagination={listProps.pagination}
              selectRowsEnabled={listProps.selectRowsEnabled}
              massiveActions={listProps.massiveActions}
              omnisearch={listProps.omnisearch}
              exports={listProps.exports}
              renderHeader={renderHeader}
              renderItem={renderItem}
              renderFooter={renderFooter}
              actionBar={listProps.actionBar}
            />
          </ListProvider>
        ) : (
          <div className="row">
            <div className="col text-center">Nessun dato da mostrare</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Manifest;

const ListItem = React.memo(({ item, index }) => {
  const appContext = useAppContext();
  const language = appContext.language;

  return (
    <React.Fragment key={index}>
      <div className="list-item m-0 p-2">
        <div className="row-1">
          <div>
            <label className="label-5">{index + 1}</label>
          </div>
          <div>
            <label className="label-2">
              {dictionary["creation_date"][language]}
            </label>
            <label className="label-5">
              {moment(item.creationDate).format("DD/MM/YYYY HH:mm")}
            </label>
          </div>
          <div>
            <label className="label-2">
              {dictionary["internal_reference"][language]}
            </label>
            <label className="label-5">{item.internalReference}</label>
          </div>
          <div>
            <label className="label-2">
              {dictionary["carrier_reference"][language]}
            </label>
            <label className="label-5">{item.carrierReference || "-"}</label>
          </div>
          <div>
            <label className="label-2">{dictionary["weight"][language]}</label>
            <label className="label-5">{item.weight.toFixed(2)} kg</label>
          </div>
          <div>
            <label className="label-2">{dictionary["volume"][language]}</label>
            <label className="label-5">
              {item.volume ? item.volume + "m3" : "-"}
            </label>
          </div>
          <div>
            <label className="label-2">{dictionary["parcels"][language]}</label>
            <label className="label-5">{item.parcels}</label>
          </div>
          <div>
            {item.carrierReference && (
              <Barcode
                value={item.carrierReference}
                format="CODE128"
                lineColor="#000000"
                background="transparent"
                displayValue={true}
                height={20}
                width={1}
                fontSize={10}
              />
            )}
          </div>
        </div>
        <div className="row-2">
          <div>
            <label className="label-2">
              {dictionary["receiver"][language]}
            </label>
            <label className="label-5">
              {item.deliveryName?.substring(0, 31)}
            </label>
          </div>
          <div>
            <label className="label-2">
              {dictionary["recipient_address"][language]}
            </label>
            <label className="label-5">{item.deliveryAddress}</label>
          </div>
          <div>
            <label className="label-2">
              {dictionary["cash_on_delivery"][language]}
            </label>
            <label className="label-5">
              {`${
                item.cashOnDeliveryValue
                  ? `${item.cashOnDeliveryValue.toFixed(2)} € (${
                      item.cashOnDeliveryCollectionMethodCode
                        ? item.cashOnDeliveryCollectionMethodCode
                        : "-"
                    })`
                  : "-"
              }`}
            </label>
          </div>
          <div>
            <label className="label-2">
              {dictionary["insurance"][language]}
            </label>
            <label className="label-5">
              {`${
                item.insuranceValue
                  ? `${item.insuranceValue.toFixed(2)} €`
                  : "-"
              }`}
            </label>
          </div>
        </div>
        <hr className="m-0 mt-2" />
      </div>
    </React.Fragment>
  );
});
