import React, {
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from "react";
import { ElementsPerPage } from "./ElementsPerPage";
import { Omnisearch } from "./Omnisearch";
import { useListContext } from "./Providers/List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppContext } from "../../../AppProvider";
import { dictionary } from "../../../utilities/dictionary";
import { Filters } from "./Filters/Filters";
import { Checkbox } from "../Checkbox";
import { Pagination } from "./Pagination";
import { LoaderAllaboxIcon } from "../LoaderAllaboxIcon";
import {
  faCheckSquare,
  faLoader,
  faX,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { Sorting } from "./Sorting";
import { DefaultDate } from "./DefaultDate";

/**
 * List - Componente per visualizzare una lista di elementi.
 *
 * @param {Ref} ref - Riferimento al componente per gestire funzionalità esterne.
 * @param {string} title - Titolo della lista.
 * @param {object} create - Informazioni per la creazione di nuovi elementi.
 * @param {object} banner - Impostazioni del banner.
 * @param {object} pagination - Impostazioni della paginazione (visibilità).
 * @param {boolean} selectRowsEnabled - Booleano per visibilità selezionamento righe
 * @param {Array} massiveActions - Azioni massive disponibili sulla lista.
 * @param {Array} actions - Azioni disponibili sulla lista.
 * @param {object} omnisearch - Impostazioni della ricerca.
 * @param {Array} exports - Opzioni di esportazione disponibili.
 * @param {function} renderItem - Funzione per rendere ciascun elemento della lista.
 * @param {object} actionBar - Impostazioni della action bar
 *
 * @example
 * <List
 *   ref={listRef}
 *   title="Le mie Spedizioni" or <label>Le mie spedizioni</label>
 *   create={{
 *     enabled: true,
 *     action: () => navigate("/shipments/create"),
 *     title: "Crea Nuova Spedizione",
 *     icon: faPlus,
 *   }}
 *   banner={{ enabled: true }}
 *   pagination={{ enabled: true }}
 *   selectRowsEnabled={true}
 *   massiveActions={[
 *     {
 *       onClick: handleMassiveAction,
 *       isDisabled: false,
 *       label: "Azione di Massa",
 *       icon: <FontAwesomeIcon icon={faTrash} />,
 *       className: "button-1 button-1-danger",
 *     },
 *   ]}
 *   omnisearch={{
 *     enabled: true,
 *     placeholder: "Cerca...",
 *   }}
 *   exports={[
 *     {
 *       title: "Esporta CSV",
 *       icon: <FontAwesomeIcon icon={faFileCsv} />,
 *       onClick: handleExportCSV,
 *       isDisabled: false,
 *     },
 *   ]}
 *   renderItem={renderItem}
 *   actionsBarFixed={false}
 * />
 */
const List = forwardRef(
  (
    {
      title,
      renderHeader,
      renderItem,
      renderFooter,
      pagination = { enabled: true },
      selectRowsEnabled = { enabled: true },
      omnisearch = { enabled: false, placeholder: "" },
      create = { enabled: false },
      banner = { enabled: false },
      massiveActions = [],
      actions = [],
      exports = [],
      actionBar = { enabled: true, fixed: true },
    },
    ref
  ) => {
    const filtersRef = useRef(null);

    const appContext = useAppContext();
    const context = useListContext();

    useImperativeHandle(ref, () => ({
      refresh: context?.refreshData,
      data: context?.data,
    }));

    const actionsBarPositionTop = appContext?.contentRef?.current
      .querySelector(".actions-bar")
      ?.getBoundingClientRect().top;

    return (
      <>
        <div className={`list-container`}>
          {banner.enabled && <div className="banner-1"></div>}
          {context?.filters?.list?.length > 0 &&
            context?.filters?.list.some((item) => item.hidden !== true) && (
              <Filters
                ref={filtersRef}
                title={context?.filters.name}
                selectors={context?.filters.list}
                preserve={context?.filters.preserve}
              />
            )}
          <div
            className={`row align-items-center ${
              !context?.data?.content?.length ? "mb-2" : ""
            }`}
          >
            <div className="col">
              {title && typeof title === "string" ? (
                <h2 className="m-0">{title}</h2>
              ) : (
                title
              )}
              {pagination.enabled && (
                <span className="data-report">
                  {context?.data?.totalElements}{" "}
                  {context?.data?.totalElements === 1
                    ? dictionary["item"][appContext?.language]
                    : dictionary["items"][appContext?.language]}{" "}
                  | {context?.data.totalPages}{" "}
                  {context?.data?.totalPages === 1
                    ? dictionary["page"][appContext?.language]
                    : dictionary["pages"][appContext?.language]}
                </span>
              )}
            </div>
            {create.enabled && (
              <div className="col d-flex justify-content-end">
                <button
                  className="button-dynamic-1"
                  onClick={() => {
                    if (create.action) {
                      create.action();
                    }
                  }}
                >
                  {create.icon && (
                    <FontAwesomeIcon icon={create.icon} size="2x" />
                  )}
                  <label>{create.title}</label>
                </button>
              </div>
            )}
          </div>
          {actionBar.enabled && (
            <div
              className={`row align-items-end mt-1 actions-bar ${
                actionBar.fixed ? "sticky" : ""
              } ${actionsBarPositionTop === 54 ? "fixed" : ""}`}
            >
              <div className={`col-${context?.selectRowsEnabled ? "4" : "8"}`}>
                <div className="d-flex align-items-end gap-2">
                  {context?.data?.content?.length > 0 &&
                    context?.selectRowsEnabled && (
                      <Checkbox
                        checked={context?.allRowsSelected}
                        onChange={context?.handleSelectAllRows}
                        label={`${
                          dictionary["select_all"][appContext?.language]
                        } (${context?.selectedRows.length} ${
                          dictionary["selected_items"][appContext?.language]
                        })`}
                      />
                    )}
                  {!context?.selectRowsEnabled && pagination.enabled && (
                    <ElementsPerPage className="flex-1" />
                  )}
                  {!context?.selectRowsEnabled &&
                    context?.sorting.length > 0 && (
                      <Sorting className="flex-2" />
                    )}
                  {!context?.selectRowsEnabled &&
                    context?.defaultDate?.enabled && (
                      <DefaultDate
                        className="flex-2"
                        selector={context?.defaultDate}
                      />
                    )}
                  {!context?.selectRowsEnabled && omnisearch.enabled && (
                    <Omnisearch className="flex-2" omnisearch={omnisearch} />
                  )}
                </div>
              </div>
              {context?.data.content?.length > 0 && (
                <div
                  className={`col-${context?.selectRowsEnabled ? "8" : "4"}`}
                >
                  <div className="d-flex align-items-center justify-content-end gap-1">
                    {context?.selectRowsEnabled &&
                      massiveActions.map((item, index) => {
                        let isDisabled = false;
                        if (item.isDisabled) {
                          isDisabled = item.isDisabled(context?.selectedRows);
                        }
                        return (
                          <button
                            key={index}
                            onClick={() => {
                              item.onClick(context?.selectedRows);
                            }}
                            disabled={
                              context?.selectedRows.length === 0 || isDisabled
                            }
                            className={item.className}
                          >
                            {item.icon}
                            {item.label}
                          </button>
                        );
                      })}
                    {selectRowsEnabled && (
                      <button
                        className={`${
                          context?.selectRowsEnabled
                            ? "button-1 button-1-dark button-1-icon"
                            : "button-label-1"
                        }`}
                        onClick={() =>
                          context?.setSelectRowsEnabled(
                            !context?.selectRowsEnabled
                          )
                        }
                      >
                        {context?.selectRowsEnabled ? (
                          <FontAwesomeIcon icon={faX} />
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faCheckSquare} />{" "}
                            {dictionary["select"][appContext?.language]}
                          </>
                        )}
                      </button>
                    )}
                    {!context?.selectRowsEnabled &&
                      exports.length > 0 &&
                      exports.map((item, index) => (
                        <button
                          key={index}
                          disabled={item.isDisabled}
                          title={item.title}
                          className="button-label-1"
                          onClick={() =>
                            item.onClick(context?.query, context?.freeSearch)
                          }
                        >
                          {item.isDisabled ? (
                            <FontAwesomeIcon icon={faLoader} spin />
                          ) : (
                            item.icon
                          )}
                        </button>
                      ))}
                  </div>
                </div>
              )}
            </div>
          )}
          {actions.length > 0 && (
            <div className="d-flex align-items-center justify-content-end gap-1">
              {actions.map((item, index) => {
                let isDisabled = false;
                if (item.isDisabled) {
                  isDisabled = item.isDisabled();
                }
                return (
                  <button
                    key={index}
                    onClick={() => {
                      item.onClick(
                        filtersRef?.current?.data,
                        context?.onSearch
                      );
                    }}
                    disabled={isDisabled}
                    className={item.className}
                    hidden={item.hidden || false}
                    title={item.title}
                  >
                    {item.loading ? (
                      <>{item.icon}</>
                    ) : (
                      <>
                        {item.icon}
                        {item.label}
                      </>
                    )}
                  </button>
                );
              })}
            </div>
          )}
          {context?.loading ? (
            <div className="text-center">
              <FontAwesomeIcon icon={faLoader} spin size="2x" />
            </div>
          ) : context?.data?.content?.length > 0 ? (
            <React.Fragment>
              {renderHeader && renderHeader(context?.data)}
              <div className="list">
                {context?.data.content.map((item, index) => {
                  return (
                    <div className="list-item-container" key={index}>
                      {context?.selectRowsEnabled && (
                        <div className={`select-row-column`}>
                          <Checkbox
                            checked={context?.selectedRows.some(
                              (rowItem) => item.id === rowItem.id
                            )}
                            onChange={() => context?.handleSelectRow(item)}
                          />
                        </div>
                      )}
                      <div className="item-column">
                        {renderItem && renderItem(item, index)}
                      </div>
                    </div>
                  );
                })}
              </div>
              {renderFooter && renderFooter(context?.data)}
            </React.Fragment>
          ) : (
            <div className="no-data mt-2">
              {dictionary["no_data_show"][appContext?.language]}
            </div>
          )}

          {pagination.enabled &&
            !context?.loading &&
            !context?.selectRowsEnabled && (
              <div className="row align-items-center mt-2">
                <div className="col">
                  <Pagination />
                </div>
                <div className="col">
                  <div className="d-flex justify-content-end align-items-center gap-1">
                    <ElementsPerPage />
                  </div>
                </div>
              </div>
            )}
        </div>
      </>
    );
  }
);

export { List };
