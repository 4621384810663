import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import { useIncomingGoodContext } from "./providers/CreateEdit";
import {
  Card,
  CardBody,
  CardFooter,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/material_blue.css";
import "flatpickr/dist/l10n/it";
import { Loader } from "../../Components/common/Loader";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import {
  getAllCarriers,
  getAllWarehouses,
} from "../../utilities/asyncSelectCallAPI";
import {
  getCarrierOptionStyle,
  getWarehouseOptionStyle,
} from "../../Components/common/AsyncSelect/utilities";
import InputBox from "../../Components/common/InputBox";
import IncomingGoodDetails from "../IncomingGoodDetails";
import { DeleteIncomingGoodProvider } from "./providers/Delete";
import { modalSettingsDefault } from "../../utilities";
import { Delete } from "./Delete";
import { ConfirmIncomingGoodProvider } from "./providers/Confirm";
import { Confirm } from "./Confirm";
import { useTypes } from "../../utilities/types";
import Select from "../../Components/common/Selects/Select";

export const CreateEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const context = useIncomingGoodContext();

  const types = useTypes();

  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const incomingGood = {
      ...context.incomingGood,
      [name]: value,
    };

    context.editIncomingGood(incomingGood);
  };

  const handleDatePickerChange = (date, dateString, picker) => {
    const incomingGood = {
      ...context.incomingGood,
      [picker.element.name]: date[0],
    };
    context.editIncomingGood(incomingGood);
  };

  const handleAsyncSelectChange = (selector, value) => {
    const incomingGood = {
      ...context.incomingGood,
      [selector]: value,
    };

    context.editIncomingGood(incomingGood);
  };

  useEffect(() => {
    if (!id) {
      if (context.autosave) {
        context.createIncomingGood();
      }
    } else {
      context.getIncomingGood();
    }
  }, []);

  return (
    <React.Fragment>
      <BreadcrumbItem
        title="Spedizioni"
        breadcrumbItem={
          id ? "Modifica movimentazione merce" : "Nuova movimentazione merce"
        }
        link={`/orders`}
      />
      <div className="content" id="container">
        <div className="row">
          <div className="col">
            <Card>
              {context.incomingGoodLoader && (
                <Loader show={context.incomingGoodLoader} />
              )}
              <CardBody>
                <h5>Informazioni generali</h5>
                <div className="row">
                  <div className="col">
                    <label className="form-label">Vettore:</label>
                    <AsyncSelect
                      name="carrier"
                      value={context?.incomingGood?.carrier}
                      onSearch={handleAsyncSelectChange}
                      loadOptions={(inputValue, pagination) => {
                        return getAllCarriers(inputValue, pagination, {
                          orderBy: "asc",
                          selector: "name",
                        });
                      }}
                      optionStyle={getCarrierOptionStyle}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Rif. Vettore:</label>
                    <InputBox
                      name="carrierReference"
                      value={context?.incomingGood?.carrierReference || ""}
                      placeholder={"CARREF123"}
                      type="text"
                      onChange={handleInputChange}
                      error={
                        context.incomingGoodError?.response?.data
                          ?.carrierReference
                      }
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Rif. Mittente:</label>
                    <InputBox
                      name="senderReference"
                      value={context?.incomingGood?.senderReference || ""}
                      placeholder={"SENREF123"}
                      type="text"
                      onChange={handleInputChange}
                      error={
                        context.incomingGoodError?.response?.data
                          ?.senderReference
                      }
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <label className="form-label">Magazzino di partenza:</label>
                    <AsyncSelect
                      name="pickupWarehouse"
                      optionValue="id"
                      optionLabel="name"
                      value={context?.incomingGood?.pickupWarehouse}
                      isClearable={true}
                      onSearch={handleAsyncSelectChange}
                      loadOptions={(inputValue, pagination) => {
                        return getAllWarehouses(inputValue, pagination, {
                          orderBy: "asc",
                          selector: "name",
                        });
                      }}
                      optionStyle={getWarehouseOptionStyle}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">
                      Magazzino di destinazione:
                    </label>
                    <AsyncSelect
                      name="deliveryWarehouse"
                      optionValue="id"
                      optionLabel="name"
                      value={context?.incomingGood?.deliveryWarehouse}
                      isClearable={true}
                      onSearch={handleAsyncSelectChange}
                      loadOptions={(inputValue, pagination) => {
                        return getAllWarehouses(inputValue, pagination, {
                          orderBy: "asc",
                          selector: "name",
                        });
                      }}
                      optionStyle={getWarehouseOptionStyle}
                    />
                  </div>
                  <div className="col-2">
                    <label className="form-label">Data di partenza:</label>
                    <Flatpickr
                      name="departureDate"
                      className={`form-control date-buttons `}
                      placeholder="Seleziona giorno"
                      options={{
                        dateFormat: "d/m/Y",
                      }}
                      onChange={handleDatePickerChange}
                      value={
                        context?.incomingGood?.departureDate &&
                        new Date(context?.incomingGood?.departureDate)
                      }
                    />
                  </div>
                  <div className="col-2">
                    <label className="form-label">Arrivo previsto:</label>
                    <Flatpickr
                      name="expectedDeliveryDate"
                      className={`form-control date-buttons `}
                      placeholder="Seleziona giorno"
                      options={{
                        dateFormat: "d/m/Y",
                      }}
                      onChange={handleDatePickerChange}
                      value={
                        context?.incomingGood?.expectedDeliveryDate &&
                        new Date(context?.incomingGood?.expectedDeliveryDate)
                      }
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-2">
                    <label className="form-label">Tipologia merce:</label>
                    <Select
                      name="goodsType"
                      onChange={(option) => {
                        handleAsyncSelectChange("goodsType", option);
                      }}
                      defaultMenuIsOpen={false}
                      className="select"
                      options={types.goods}
                      value={context?.incomingGood?.goodsType}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Note mittente:</label>
                    <InputBox
                      name="senderNote"
                      value={context?.incomingGood?.senderNote || ""}
                      placeholder={"Note"}
                      type="text"
                      onChange={handleInputChange}
                      error={
                        context.incomingGoodError?.response?.data?.senderNote
                      }
                    />
                  </div>
                </div>
                {context?.incomingGood?.id && (
                  <div className="row mt-3">
                    <div className="col">
                      <IncomingGoodDetails
                        parentId={context?.incomingGood?.id}
                      />
                    </div>
                  </div>
                )}
                {!context?.autosave && (
                  <div className="row mt-3 justify-content-between">
                    <div className="col">
                      <Link to={"/incoming-goods"} className="btn btn-link p-0">
                        Torna all'elenco
                      </Link>
                    </div>
                    <div className="col text-end">
                      {!context.incomingGood?.id && (
                        <button
                          className="btn btn-outline-success btn-sm btn btn-light"
                          onClick={() => {
                            context.createIncomingGood();
                          }}
                        >
                          Aggiungi dettagli
                        </button>
                      )}
                      {context.incomingGood?.id && (
                        <button
                          className="btn btn-outline-success btn-sm btn btn-light"
                          onClick={() => {
                            if (context.callback)
                              context.callback(context.incomingGood);
                          }}
                        >
                          Salva
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col text-end">
            {context.incomingGood?.id && (
              <React.Fragment>
                <button
                  className="btn btn-outline-danger btn-sm btn btn-light ms-2"
                  onClick={() => {
                    setModalSettings({
                      ...modalSettings,
                      title: "Elimina movimentazione merce",
                      icon: "ph-check",
                      open: !modalSettings.open,
                      action: "delete",
                      incomingGoods: [context.incomingGood?.id],
                    });
                  }}
                >
                  <i className="ph-trash me-2"></i>
                  Elimina
                </button>
                <button
                  className="btn btn-outline-success btn-sm btn btn-light ms-2"
                  onClick={() => {
                    setModalSettings({
                      ...modalSettings,
                      title: "Conferma movimentazione merce",
                      icon: "ph-check",
                      open: !modalSettings.open,
                      action: "confirm",
                      incomingGoods: [context.incomingGood?.id],
                    });
                  }}
                >
                  <i className="ph-check me-2"></i>
                  Conferma
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalSettings.open}
        className="modal-content"
        size={modalSettings.size || "md"}
      >
        <ModalHeader
          toggle={() =>
            setModalSettings({
              ...modalSettingsDefault,
              open: !modalSettings.open,
            })
          }
        >
          <span className="modal-title">
            <i className={modalSettings.icon + " me-2"}></i>
            {modalSettings.title}
          </span>
        </ModalHeader>
        <ModalBody>
          {modalSettings.action === "delete" && (
            <DeleteIncomingGoodProvider
              data={modalSettings.incomingGoods}
              callback={() => {
                setModalSettings({
                  modalSettingsDefault,
                  open: !modalSettings.open,
                });
                navigate("/incoming-goods");
              }}
            >
              <Delete />
            </DeleteIncomingGoodProvider>
          )}
          {modalSettings.action === "confirm" && (
            <ConfirmIncomingGoodProvider
              data={modalSettings.incomingGoods}
              callback={() => {
                setModalSettings({
                  modalSettingsDefault,
                  open: !modalSettings.open,
                });
                navigate("/incoming-goods");
              }}
            >
              <Confirm />
            </ConfirmIncomingGoodProvider>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
