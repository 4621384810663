import React from "react";
import { Button } from "reactstrap";
import { useIncomingGoodContext } from "./providers/Confirm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";

export const Confirm = () => {
  const context = useIncomingGoodContext();

  return (
    <form>
      <div className="row mb-3">
        <div className="col">
          <label>
            {context.incomingGoods?.length > 1
              ? "Sei sicuro di voler confermare le bolle entrata merce selezionate?"
              : "Sei sicuro di voler confermare la seguente movimentazione merce?"}
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            if (context.callback) {
              context.callback();
            }
          }}
        >
          Annulla
        </button>
        <Button
          className="btn ms-3"
          onClick={() => context.confirmIncomingGoods()}
          disabled={context.incomingGoodsLoader}
        >
          {context.incomingGoodsLoader ? (
            <FontAwesomeIcon icon={faLoader} spin />
          ) : (
            "Conferma"
          )}
        </Button>
      </div>
    </form>
  );
};
